<template>
  <div>
    <b-modal
      ref="my-modal"
      centered
      cancel-title="No"
      cancel-variant="outline-secondary"
      title-tag="div"
    >
      <validation-observer ref="simple">
        <b-card-code>
          <b-form>
            <b-row>

              <b-col
                md="12"
                xl="6"
              >
                <b-form-group label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <label>الأسم</label>

                    <b-form-input
                      id="helperInput"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="الاسم"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                xl="6"
              >
                <b-form-group label-for="fname">
                  <validation-provider
                    #default="{ errors }"
                    name="fname"
                    rules="required"
                  >
                    <label>أسم الأب</label>
                    <b-form-input
                      id="helperInput"
                      v-model="FName"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                xl="6"
              >
                <b-form-group label-for="lname">
                  <validation-provider
                    #default="{ errors }"
                    name="lname"
                    rules="required"
                  >
                    <label>النسبة </label>

                    <b-form-input
                      id="helperInput"
                      v-model="FamilyName"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="12"
                xl="6"
              >
                <b-form-group label-for="mname">
                  <validation-provider
                    #default="{ errors }"
                    name="mname"
                    rules="required"
                  >
                    <label>أسم الأم</label>

                    <b-form-input
                      id="helperInput"
                      v-model="Mname"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
              <validation-provider
              #default="{ errors }"
                    name="familyNatural"
                    rules="required"
              >
                <b-form-group
                  label-for="familyNatural"
                  :state="errors.length > 0 ? false : null"                >
                  <label> طبيعة العائلة</label>

                  <v-select
                    v-model="family_status_id"
                  
                    :options="FamilyStatus"
                    label="name"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-col>

            <!-- <b-col md="6" xl="4">
              <b-form-group label-for="phone">
                <label>تاريخ التسجيل</label>
                <b-form-input v-model="familyFile.registration_date" readonly />
              </b-form-group>
            </b-col> -->

            <b-col md="6" xl="6" v-if="family_status_id !== 2">
              <validation-provider
              #default="{ errors }"
                    name="fname"
                    
              >
                <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                >
                  <label>تاريخ النزوح</label>
                  <flat-pickr
                    v-model="displacement_date"
                    class="form-control"
                    placeholder="حدد تاريخ النزوح"
                    :config="{ maxDate: maxDate }"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>


              <b-col
                md="12"
                xl="6"
              >
                <b-form-group label-for="bplace">
                  <validation-provider
                    #default="{ errors }"
                    name="field"
                    rules="required"
                  >
                    <label> مكان الولادة</label>
                    <b-form-input
                      id="helperInput"
                      v-model="PlaceBir"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
              md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="BirthDate"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> تاريخ الميلاد</label>

                    <flat-pickr
                      v-model="dateOfBirth"
                      class="form-control"
                      placeholder="حدد تاريخ الميلاد"
                      :config="{ maxDate: max }"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
              md="6"
                xl="6">
            <!-- Company -->
            <validation-provider
            #default="{ errors }"
              name="Contact"
              rules="required"
            >
              <b-form-group
                label="رقم الهاتف"
                label-for="contact"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  id="contact"
                  v-model="phone"
                  
                />
  
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="select"
                  rules="required"
                >
                  <b-form-group
                    label-for="time"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الجنس</label>
                    <v-select
                      v-model="selectgender"
                      :reduce="(val) => val.value"
                      :options="optiongender"
                      @input="getWorkType(selectgender)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <!-- Numeral Formatting -->

              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="social status"
                  rules="required"
                >
                  <b-form-group
                    label-for="time"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الحالة الأجتماعية</label>
                    <v-select
                      v-model="selectstatus"
                      :reduce="(val) => val.value"
                      :options="MartialState"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="studylevel"
                  rules="required"
                >
                  <b-form-group
                    label-for=""
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> التحصيل العلمي</label>
                    <v-select
                      v-model="selectedStudy"
                      :reduce="(val) => val.value"
                      :options="optionStudy"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="studystate"
                  rules="required"
                >
                  <b-form-group
                    label-for=""
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> حالة التعليم</label>
                    <v-select
                      v-model="selectedStudyState"
                      :reduce="(val) => val.value"
                      :options="EducationalState"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="person state"
                  rules="required"
                >
                  <b-form-group
                    label-for=""
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> حالة الفرد</label>
                    <v-select
                      v-model="selectedS"
                      :reduce="(val) => val.value"
                      :options="RegistrationStatuses"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NewWork"
                  rules="required"
                >
                  <b-form-group
                    label-for=""
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> العمل الحالي</label>
                    <v-select
                      v-model="selectedWork"
                      :reduce="(val) => val.value"
                      :options="optionJobs"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LastWork"
                  rules="required"
                >
                  <b-form-group
                    label-for=""
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> العمل السابق</label>
                    <v-select
                      v-model="selectedLastWork"
                      :reduce="(val) => val.value"
                      :options="optionJobs"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

            </b-row>
            <b-row>
              <b-col
                md="6"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nationality"
                  rules="required"
                >
                  <b-form-group
                    label-for="time"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الجنسية</label>
                    <v-select
                      v-model="selectedNa"
                      :reduce="(val) => val.value"
                      :options="optionNationality"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="4"
                xl="4"
              >
                <b-form-group label-for="number">
                  <!-- <validation-provider #default="{ errors }" name="note" rules="required"> -->
                  <label> ملاحظات عامة</label>
                  <b-form-textarea
                    id="textarea-default"
                    v-model="singleNotes"
                    placeholder="الملاحظات"
                    rows="1"
                  />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card-code>

      </validation-observer>
      <!-- </b-card-code> -->

      <template #modal-footer>
        <b-button
          variant="purple"
          @click="save"
        >
          <span class="align-middle"> حفظ </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from 'vue-select'
// import person from './person.vue';

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BFormFile,
  BFormGroup,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BModal,
  BFormInvalidFeedback,
  BTable,
  BFormDatepicker, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import 'flatpickr/dist/flatpickr.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  required, max, is_earlier, length,
} from '@validations'
import { personDetails } from '@/@core/mixins/personDetails.js'

// import {
//   BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
// } from 'bootstrap-vue'
export default {
  name: 'FamilyNumber',

  components: {
    BTable,
    length,
    is_earlier,
    BCardCode,
    BModal,
    required,
    BFormInvalidFeedback,
    BButton,
    max,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    flatPickr,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    ToastificationContent,
    BRow,

    BCol,
  },
  mixins: [personDetails],

  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth())

    return {
      jobs: [],
      test: false,
      RegistrationStatuses: [],
      notesFamily: '',
      optionJobs: [],
      saveWeakness: false,
      saveDocuments: false,
      saveNumbers: false,

      selectstatus: '',
      dateOfBirth: '',
      singleNotes: '',
      phone:'',
      displacement_date:'',
      family_status_id:'',
      name: '',
      lName: '',
      father: '',
      FamilyName: '',
      mother: '',
      Mname: '',
      FName: '',
      lastName: '',
      PlaceBir: '',
      numberFamily: 0,
      numberChild: 0,
      dateReg: '',
      dateDisp: '',
      selectedS: '',
      selectedMom: '',
      optionMom: [],
      selectedWeak: [],
      selectedStudyState: '',
      selectedLastWork: '',
      nowNote: '',
      note: '',
      lastNote: '',
      lastWork: '',
      AddedPerson: false,
      selectedStudy: '',
      selectedWork: '',
      selectedRel: '',
      selectedNa: '',
      selectgender: '',
      selectedFamily: '',
      selectedknow: '',
      termW: false,
      number: '',
      numberphone: '',
      selectedCall: '',
      value: null,

      dateRelease: '',
      dateEnd: '',
      del: false,
      rules: {
        required,
        isBeyond: {
          compare: this.dateRelease,
          dateType: 'تاريخ الانتهاء',
        },
      },
      number: '',
      value: '',
      alldocuments: [],
      Personphones: [],
      Personweakness: [],
      PersonDocuments: [],
      optionTo: [],
      optionDocuments: [],
      optionsWeak: [],
      contactType: [],

      optionWeakness: [],
      EducationalState: [],

      optiongender: [],
      // optionState: [ "زائر",  "مسجل" ],
      optionNationality: [],
      optionStudy: [],
      optionRelationship: [],
      MartialState: [],
      FamilyStatus : [],
      max: maxDate,
      msg: {
        required: 'هذا الحقل مطلوب',
      },

      personid: '',
      id: '',
      weaknotes: '',
    }
  },

  created() {
    this.getAlldrowpDown()
    this.getFamilyStatus()
  },

  methods: {
    save() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
              this.id = this.$store.state.id
              let data

              // if (!this.term) {
              data = {
                first_name: this.name,
                last_name: this.FamilyName,
                father_name: this.FName,
                mother_name: this.Mname,
                birth_date: this.dateOfBirth,
                birth_place: this.PlaceBir,
                nationality_id: this.selectedNa,
                martial_status_id: this.selectstatus,
                family_relationship_id: this.selectedRel,
                educational_attainment_id: this.selectedStudy,
                educational_status: this.selectedStudyState,
                current_work_id: this.selectedWork,
                current_work_note: this.nowNote,
                last_work_note: this.lastNote,
                last_work_id: this.selectedLastWork,
                registration_status: this.selectedS,
                notes: this.singleNotes,
                gender: this.selectgender,
                phone: this.phone,
                family_status_id:this.family_status_id,
                displacement_date:this.displacement_date,

              }
              function replaceUndefinedOrNull(key, value) {
                if (value === null || value === '' || value === undefined) {
                  return undefined
                }

                return value
              }

              const datajson = JSON.stringify(data, replaceUndefinedOrNull)
              const dataparse = JSON.parse(datajson)
              function isEmptyObject(obj) {
                return JSON.stringify(obj) === '[{}]'
              }
              for (const key of Object.keys(dataparse)) {
                if (isEmptyObject(dataparse[key])) {
                  delete dataparse[key]
                }
              }
              // let k=Object.entries(exampleObject).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})

              this.$http.post('/api/v1/mobile-team/un-registered-persons', dataparse).then(res => {
                this.$emit('getunregister')

                this.$swal({
                  title: '',

                  text: `تم اضافة  ${this.name}
        
   `,
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });

                (this.name = ''),
                (this.FamilyName = ''),
                (this.FName = ''),
                (this.Mname = ''),
                (this.dateOfBirth = ''),
                (this.PlaceBir = ''),
                (this.selectedNa = ''),
                (this.selectstatus = ''),
                (this.selectedRel = ''),
                (this.selectedStudy = ''),
                (this.selectedStudyState = ''),
                (this.selectedWork = ''),
                (this.nowNote = ''),
                (this.lastNote = ''),
                (this.selectedLastWork = ''),
                (this.selectedS = ''),
                (this.singleNotes = ''),
                (this.selectgender = '')

                requestAnimationFrame(() => {
                  this.$refs.simple.reset()
                })
              })
            }
          })
          .catch(error => {
            this.$swal({
              title: '',
              text: `${error.response.data.message}`,
              icon: 'error',
              confirmButtonText: 'موافق',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
      })
      //  this.$refs['simple'].reset()
    },
    getWorkType(id) {
      this.optionJobs = []
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const { work_types } = res.data.data
        work_types.forEach(el => {
          if (el.gender == id || el.gender == 0) {
            this.optionJobs.push({ label: el.name, value: el.id })
          }
        })
      })
    },
    getFamilyStatus(){
      this.$http.get("/api/v1/family_statuses").then((response) => {
          this.FamilyStatus = response.data.data;
        });
    },
    getunregister() {},
    resetModal() {
      this.phones = [{ number: '', notes: '', contact_type: '' }]
      requestAnimationFrame(() => {
        this.$refs.phonesimple.reset()
      })
    },
    show() {
      this.$refs['my-modal'].show()
    },

  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  <style scoped>
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .btn-purple {
    color: white;
    background-color: #0971b8 !important;
    width: 200px;
  }
  .btn-edit {
    color: white;
    width: 200px;
    background-color: #28c76f !important;
  }
  .btn-delete {
    color: white;
    width: 200px;
    background-color: #ea5455 !important;
  }

  .but {
    top: 25px;
  }
  </style>
