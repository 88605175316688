<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="  أضافة الحضور">
          <b-row v-for="attendee in dataAttandence">

            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="attendee_type"
                rules="required"
              >
                <b-form-group label-for="subject">
                  <label> نوع الحضور</label>

                  <v-select
                    v-model="attendee.attendee_type"
                    :reduce="(val) => val.value"
                    :options="optionType"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              v-if="attendee.attendee_type=='App\\Models\\Person'"
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Person"
                rules="required"
              >
                <b-form-group label-for="subject">
                  <label> الفرد</label>

                  <v-select
                    v-model="attendee.attendee_id"
                    :reduce="(val) => val.value"
                    :options="optionPersonList"
                    :filterable="true"   
                    :searchable="true"
                    placeholder="search"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              v-else-if="attendee.attendee_type=='App\\Models\\User'"
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="User"
                rules="required"
              >
                <b-form-group label-for="subject">
                  <label> المستخدم</label>

                  <v-select
                    v-model="attendee.attendee_id"
                    :reduce="(val) => val.value"
                    :options="optionUser"
                    :filterable="true"   
                    :searchable="true"
                    placeholder="search"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              v-else-if="attendee.attendee_type=='App\\Models\\Volunteer'"
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="UnRegisteredPerson"
                rules="required"
              >
                <b-form-group label-for="subject">
                  <label> متطوع وصول غير مسجل  </label>

                  <v-select
                    v-model="attendee.attendee_id"
                    :reduce="(val) => val.value"
                    :options="optionUnRegisteredORV"
                    :filterable="true"   
                    :searchable="true"
                    placeholder="search"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              v-else
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="UnRegisteredPerson"
                rules="required"
              >
                <b-form-group label-for="subject">
                  <label> الفرد غير المسجل</label>

                  <v-select
                    v-model="attendee.attendee_id"
                    :reduce="(val) => val.value"
                    :options="optionUnRegisteredPerson"
                    :filterable="true"   
                    :searchable="true"
                    placeholder="search"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            
            <b-col
              md="3"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                >
                  <label>تاريخ  </label>
                  <flat-pickr
                    v-model="attendee.attendance_date"
                    class="form-control"
                    placeholder="حدد تاريخ "
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

          </b-row>
          <b-row>
            <b-col
              md="6"
              xl="4"
            >
              <b-button
                variant="purple"
                @click="addUnRegister"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">إضافة  اشخاص غير مسجلين </span>
              </b-button>
             
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="6"
              xl="4"
            >
              <b-button
                variant="purple"
                @click="addSession"
               
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">    حفظ </span>
              </b-button>
              
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
      <unregister
        ref="childComponent"
        @getunregister="getunregister"
      />
    </validation-observer>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,

  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import unregister from './unregister.vue'

export default {
  name: 'AddSession',
  components: {
    flatPickr,
    BDropdown,

    ToastificationContent,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,
    unregister,
    BCol,
  },

  data() {
    return {
      dataAttandence: [
        {
          attendance_date: '',
          mobile_team_session_id: null,
          attendee_type: '', // or "App\Models\User" or "App\\Models\\UnRegisteredPerson"
          attendee_id: '', // Provide the actual ID of the attendee
        },
      ],
      optionType: 
      [{label:'شخص مسجل',value:'App\\Models\\Person'}, 
      {label:' مستخدم',value:'App\\Models\\User'}, 
      {label:'شخص غير مسجل',value:'App\\Models\\UnRegisteredPerson'},
      {label: "متطوع وصول غير مسجل",value:'App\\Models\\Volunteer'}
    ],
      optionAttendee: [],
      optionUser: [],
      optionPersonList: [],
      optionUnRegisteredPerson: [],
      optionUnRegisteredORV: [],
    }
  },

  computed: {},

  created() {
    this.getType()
    this.getunregister()
    this.getemploye()
    this.getunregisterORV()
  },

  methods: {

    addUnRegister() {
      this.$refs.childComponent.show()
    },
    getType() {
      this.optionPersonList=[]
      this.$http.get('/api/v1/mobile-team/get-session-kind-list').then(res => {
        this.optionKind = res.data
      })
      this.$http.get('/api/v1/service_maintainer/persons_with_services_list').then(res => {
       console.log(res.data);
        res.data.data.forEach(el => {
          this.optionPersonList.push({ value: el.id, label: `${el.name}  , ${el.mother_name} ` })
        })
       
      })
    },
    getemploye() {
      this.optionUser = []
      this.$http.get('/api/v1/get-all-users').then(res => {
  
        res.data.forEach(el => {
          this.optionUser.push({ value: el.id, label: `${el.first_name} ${el.last_name} ` })
        })
        // console.log(this.optionemploye);
      })
    },
    getunregister() {
      this.optionUnRegisteredPerson = []
      // this.$refs.childComponent.hide()
      this.$http.get('/api/v1/mobile-team/un-registered-persons').then(res => {
  //      console.log(res.data);
        res.data.forEach(el => {
          this.optionUnRegisteredPerson.push({ value: el.id, label: `${el.first_name} ${el.father_name} ${el.last_name} , ${el.mother_name} ` })
        })
        //
      })
    },
    getunregisterORV() {
      this.optionUnRegisteredORV = []
      // this.$refs.childComponent.hide()
      this.$http.get('/api/v1/volunteer').then(res => {
  //      console.log(res.data);
        res.data.forEach(el => {
           this.optionUnRegisteredORV.push({ value: el.id, label: el.name })
        })
        //
      })
    },

    addSession() {
      this.dataAttandence[0].mobile_team_session_id=this.$route.params.id
      // console.log(data);
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules
          .validate()
          .then((success) => {
            if (success) {
      const url = '/api/v1/mobile-team/session-attendence'
      this.$http
        .post(url, this.dataAttandence[0])
        .then(res => {
          this.$swal({
            title: 'تم إضافة  بنجاح',

            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.dataAttandence=[{
            attendance_date: '',
          mobile_team_session_id: null,
          attendee_type: '', // or "App\Models\User" or "App\\Models\\UnRegisteredPerson"
          attendee_id: '',

          }]
          requestAnimationFrame(() => {
                  this.$refs.simpleRules.reset();
                });
        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$swal({
            title: `${error.response.data.message}`,

            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }})})
    },

  },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
